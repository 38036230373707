import React from "react";
import ReactTooltip from "react-tooltip";
import "../../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { formatShortDate } from "../utils";
const urlRegex = /(https?:\/\/[^\s]+)/g;

const tutor = () => [
  {
    Header: "Student",
    accessor: "student_name",
    id: "student_name",
    Cell: ({ row }) => {
      return (
        <a
          href={`/backend/students/${row.original.student_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.values.student_name}
        </a>
      );
    },
  },
  {
    Header: "GRAD YEAR",
    accessor: "graduation_year",
    Cell: ({ row }) => row.values.graduation_year || "-",
  },
  {
    Header: "TUTOR",
    accessor: "tutor_name",
    Cell: ({ row }) => row.values.tutor_name || "-",
  },
  {
    Header: "LAST TUTOR SESSION",
    accessor: "last_tutor_session",
    Cell: ({ row }) => formatShortDate(row.values.last_tutor_session),
  },
  {
    Header: "NEXT TUTOR SESSION",
    accessor: "next_tutor_session",
    Cell: ({ row }) => formatShortDate(row.values.next_tutor_session),
  },
  {
    Header: "TUTOR HOURS USED/TOTAL HOURS AVAILABLE",
    accessor: "used_hours",
    Cell: ({ row }) =>
      (row.values.used_hours || 0) + " / " + (row.values.total_hours || 0) ||
      "-",
  },
  {
    Header: "TOTAL HOURS REMAINING",
    accessor: "total_hours",
    Cell: ({ row }) => row.values.total_hours - row.values.used_hours || "0",
  },
  {
    Header: "NOTES",
    accessor: "notes_dashboard",
    Cell: ({ value }) => {
      const isUrl =
        typeof value === "string" &&
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value);
      const overflow = isUrl
        ? isUrl
          ? "hidden"
          : "visible"
        : isUrl
        ? "visible"
        : "hidden";
      const maxWidth = isUrl ? "260px" : isUrl ? "180px" : "none";

      const urlMatch = typeof value === "string" ? value.match(urlRegex) : null;
      const displayValue = urlMatch ? urlMatch[0] : value;

      const formattedValue = (value) => {
        const words = value.split(" ");
        const divs = [];
        for (let i = 0; i < words.length; i += 10) {
          const chunk = words.slice(i, i + 10).join(" ");
          divs.push(`<div>${chunk}</div>`);
        }
        return divs.join("");
      };

      const shorterValue = (value) => {
        var truncatedValue = value?.substring(0, 200);
        if (truncatedValue.length < value.length) {
            const lastSpaceIndex = truncatedValue.lastIndexOf(' ');
            if (lastSpaceIndex !== -1) {
                truncatedValue = truncatedValue.substring(0, lastSpaceIndex+1);
            }
        }
        return truncatedValue ;
      };

      return (
        <div style={{ maxWidth, overflow, maxHeight: '20%'}}>
          {isUrl ? (
            <span style={{ color: "black", textDecoration: "none" }}>
              {displayValue}
            </span>
          ) : (
            <span>{value ? shorterValue(value) : ""}</span>
          )}
          {value?.length > 200 && (
            <span>
              <ReactTooltip
                place="left"
                effect="solid"
                html={true}
                className="text-left"
                getContent={() => formattedValue(value)}
              />
              <span data-tip class="label label-default">. . .</span>
            </span>
          )}
        </div>
      );
    },
  },
];
export { tutor };
