import React from "react";

function FormulaNote({years}) {
    return (
        <div>
          <p>For the year {years[0]}: Percentage of students who had their last counselor meeting less than 75 days ago. </p>
          <p>For the year {years[1]} and beyond: Percentage of students who had their last counselor meeting less than 90 days ago. </p>
          <p>Students enrolled for less than 30 days are considered in a healthy state. </p>
        </div>
      );
      
}

export default FormulaNote;
