import React, { useEffect, useState } from 'react';
import ReportTable from './ReportTable';
import "../../../../assets/stylesheets/backend/healthy_report/healthy_report.scss";
import FormulaNote from './FormulaNote';

function Report({years}) {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataReport, setDataReport] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('counselors');
        const data = await response.json();

        const responseData = await fetch('get_data');
        const dataReport = await responseData.json();

        const formattedDataReport = Object.values(dataReport[0]).map((columnData) => ({
          ...columnData,
        }));
        setColumns(data);
        setDataReport(formattedDataReport);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []); 


  return (
    <div className="report-container">
      <h1 className='report-title-filters'>Healthy Contact</h1>
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <ReportTable columns={columns} tableData={dataReport}/>
      )}
      <FormulaNote years={years}/>
    </div>
  );
  
}

export default Report;
