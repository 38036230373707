import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import Select from "react-select";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";

function Filters({ grad_year_list, program_list, filterData }) {
  const [globalState, globalActions] = useGlobal();
  let { gradYear, programNames } = globalState.essayCoachPerformanceFilters;
  const { handleStoreChange } = globalActions.essayCoachPerformanceFilters;

  const filterDataParams = (commonFilters) => commonFilters;

  const filterParams = filterDataParams({
    filter_by_grad_year: gradYear,
    filter_by_programs: programNames,
  });

  const setDropdownGradYear = (selectedYears) => {
    const years = selectedYears.map((option) => option.value).join(",");
    handleStoreChange("gradYear", years);
    const filterParams = filterDataParams({
      filter_by_grad_year: years,
      filter_by_programs: programNames,
    });
    filterData(filterParams, "gradYear");
  };

  const setDropdownProgram = (selectedPrograms) => {
    const programs = selectedPrograms.map((option) => option.value);
    handleStoreChange("programNames", programs);
    const filterParams = filterDataParams({
      filter_by_grad_year: gradYear,
      filter_by_programs: programs,
    });
    filterData(filterParams, "programNames");
  };

  useEffect(() => {
    const defaultProgramNames = [
      "College Admissions Program",
      "College Admissions Elite",
      "College Admissions Select",
      "Essay Editing",
    ];
    handleStoreChange("programNames", defaultProgramNames);
    filterParams.filter_by_programs = defaultProgramNames;
    filterData(filterParams, "programNames");
  }, []);

  return (
    <div className="align-filter">
      <span className="dashboard-title-filters">Grad Year:</span>
      <Select
        className="grad-year-filter-select"
        isMulti
        value={grad_year_list.reduce((accumulator, year) => {
          if (gradYear.includes(year)) {
            accumulator.push({
              value: year,
              label: year,
            });
          }
          return accumulator;
        }, [])}
        options={grad_year_list.map((year) => ({ value: year, label: year }))}
        onChange={setDropdownGradYear}
        placeholder="Select a grad year"
      />
      <span className="dashboard-title-filters">Program:</span>
      <Select
        className="grad-year-filter-select"
        isMulti
        value={program_list.reduce((accumulator, program) => {
          if (programNames.includes(program.program_name)) {
            accumulator.push({
              value: program.program_name,
              label: program.program_name,
            });
          }
          return accumulator;
        }, [])}
        options={program_list.map((program) => ({
          value: program.program_name,
          label: program.program_name,
        }))}
        onChange={setDropdownProgram}
        placeholder="Select a Program"
      />
    </div>
  );
}

export { Filters };
