import React from "react";
import "../../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
const validate_status = (value) => {
  if (value && value.status) {
    switch (value.status) {
      case "Completed":
        return (
          <img src={"/images/iconos_dashboard/completed.png"} alt="Completed" />
        );
      case "In progress":
        return (
          <img
            src={"/images/iconos_dashboard/in_progress.png"}
            alt="In progress"
          />
        );
      case "Not started":
        return (
          <img
            src={"/images/iconos_dashboard/not_started.png"}
            alt="Not started"
          />
        );
      case "Registered":
        return (
          <img
            src={"/images/iconos_dashboard/registered.png"}
            alt="Registered"
          />
        );
      case "Opted out":
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
      default:
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
    }
  } else {
    return (
      <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
    );
  }
};

const workshops = () => [
  {
    Header: "Student",
    accessor: "student_name",
    id: "student_name",
    Cell: ({ row }) => {
      return (
        <a
          href={`/backend/students/${row.original.student_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.values.student_name}
        </a>
      );
    },
  },
  {
    Header: "Major and Career Exploration Workshop",
    accessor: "major_and_career_exploration_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Stand Out Factor Workshop",
    accessor: "stand_out_factor_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "College Admissions 101 Workshop",
    accessor: "college_admissions_101_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Mastering Academic Success Workshop",
    accessor: "mastering_academic_success_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Standing Out as an Underclassmen Workshop",
    accessor: "standing_out_as_an_underclassmen_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Paying for College Workshop",
    accessor: "paying_for_college_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Take a Practice ACT or SAT",
    accessor: "take_a_practice_act_or_sat",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Junior Scholarship Workshop",
    accessor: "junior_scholarship_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Activities & Honors Workshop",
    accessor: "activities_honors_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Common App Personal Statement Workshop",
    accessor: "common_app_personal_statement_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Common Application Workshop",
    accessor: "common_application_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Supplemental Essay Workshop",
    accessor: "supplemental_essay_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "FAFSA/CSS Profile Workshop",
    accessor: "fafsa_css_profile_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Senior Scholarship Bootcamp",
    accessor: "senior_scholarship_bootcamp",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "College & Career Transition Workshop",
    accessor: "college_career_transition_workshop",
    Cell: ({ value }) => validate_status(value),
  },
];
export { workshops };
