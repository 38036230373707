import React from 'react';
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";


function StatusIcon () {

    return (
        <div className="left-menu-button-tasks">
          <div className="meaning-icons">
            <img src={'/images/iconos_dashboard/not_started.png'} alt="Not started" className='icon-status'/>
            <p>Not started</p>
          </div>
          <div className="meaning-icons">
            <img src={'/images/iconos_dashboard/registered.png'} alt="Registered" className='icon-status'/>
            <p>Registered</p>
          </div>
          <div className="meaning-icons">
            <img src={'/images/iconos_dashboard/in_progress.png'} alt="In progress" className='icon-status'/>
            <p>In Progress</p>
          </div>
          <div className="meaning-icons">
            <img src={'/images/iconos_dashboard/completed.png'} alt="Completed" className='icon-status'/>
            <p>Completed</p>
          </div>
          <div className="meaning-icons">
            <img src={'/images/iconos_dashboard/opted_out.png'} alt="Opted out" className='icon-status'/>
            <p>Opted out</p>
          </div>
        </div>
    )
  
};

export default StatusIcon;
