import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import Select from "react-select";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
function Filters({ grad_year_list, filterData }) {
  const [globalState, globalActions] = useGlobal();
  let { gradYear } = globalState.counselorPerformanceFilters;
  const { handleStoreChange } = globalActions.counselorPerformanceFilters;

  const filterDataParams = (commonFilters) => commonFilters;

  const filterParams = filterDataParams({
    filter_by_grad_year: gradYear,
  });

  const setDropdownGradYear = (selectedYears) => {
    const years = selectedYears.map((option) => option.value).join(",");
    handleStoreChange("gradYear", years);
    const filterParams = filterDataParams({
      filter_by_grad_year: years,
    });
    filterData(filterParams, "gradYear");
  };

  return (
    <>
      <div className="align-filter">
        <span className="dashboard-title-filters">Grad Year:</span>
        <Select
          className="grad-year-filter-select"
          isMulti
          value={grad_year_list.reduce((accumulator, year) => {
            if (gradYear.includes(year)) {
              accumulator.push({
                value: year,
                label: year,
              });
            }
            return accumulator;
          }, [])}
          options={grad_year_list.map((year) => ({ value: year, label: year }))}
          onChange={setDropdownGradYear}
          placeholder="Select a grad year"
        />
      </div>
    </>
  );
}

export { Filters };
