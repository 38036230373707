import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import useGlobal from "../../../hooks/store";
Modal.setAppElement("*");

const EmailModal = (props) => {
  const {
    openModal,
    isOpen,
    onRequestClose,
    subject,
    setSubject,
    body,
    setBody,
    sendEmail,
    totalData,
    filters,
    section,
  } = props;
  const [globalState, globalActions] = useGlobal();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const columns = 6;

  const toggleStudentSelection = (studentId) => {
    if (selectedStudents.includes(studentId)) {
      setSelectedStudents(
        selectedStudents.filter((student_id) => student_id !== studentId)
      );
    } else {
      setSelectedStudents([...selectedStudents, studentId]);
    }
  };

  const handleSendEmail = () => {
    sendEmail(selectedStudents);
    openModal();
    setSelectedStudents([]);
    setBody("");
    setSubject("");
  };

  const handleClose = () => {
    setSelectedStudents([]);
    setBody("");
    setSubject("");
    openModal();
  };

  const getData = () => {
    globalActions.dashboard
      .filterDataBy({
        ...filters,
        section: section,
        page: 1,
        per_page: totalData,
      })
      .then((response) => {
        setSelectedStudents(
          response.data.records.map((student) => student.student_id)
        );
        setStudents(response.data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (totalData > 0) {
      getData();
    }
  }, [isOpen, totalData]);

  return (
    <div className="left-menu-button">
      <button className="date-filter-button" onClick={openModal}>
        Send Mass Email
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Send Email PopUp"
      >
        <h2>Send Mass Email</h2>
        <div className="row">
          <h3 style={{ marginLeft: "15px" }}>Selected Students:</h3>
          {[...Array(columns).keys()].map((colIndex) => (
            <div key={colIndex} className={`col-md-${12 / columns}`}>
              {students.map((student, studentIndex) => {
                if (studentIndex % columns === colIndex) {
                  return (
                    <label
                      key={student.student_id}
                      className="form-check student-label"
                    >
                      <input
                        type="checkbox"
                        value={student.student_id}
                        checked={selectedStudents.includes(student.student_id)}
                        onChange={() =>
                          toggleStudentSelection(student.student_id)
                        }
                      />
                      <span className="student-name">
                        {student.student_name}
                      </span>
                    </label>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          ))}
        </div>
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="custom-input"
        />
        <h6 style={{ fontWeight: "bold", color: "gray" }}>
          Use &lt;student_name&gt; where you want to place student's name
        </h6>
        <textarea
          placeholder="Body"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          className="custom-textarea"
        />
        <button className="send-email-button" onClick={handleSendEmail}>
          Send Emails
        </button>
        <button className="send-email-button" onClick={handleClose}>
          Cancel
        </button>
      </Modal>
    </div>
  );
};
export default EmailModal;
