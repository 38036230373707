import React from "react";

function FormulaNote() {
  return (
    <div>
      <p>
        Year Columns: Amount of students with that Graduation Year assigned to a
        Counselor.{" "}
      </p>
      <p>
        Program Columns: Amount of students with a Select, Premier and Elite
        Program assigned to a Counselor.{" "}
      </p>
    </div>
  );
}

export default FormulaNote;
